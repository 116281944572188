import { render, staticRenderFns } from "./Beranda.vue?vue&type=template&id=c249aff8&scoped=true&"
import script from "./Beranda.vue?vue&type=script&lang=js&"
export * from "./Beranda.vue?vue&type=script&lang=js&"
import style0 from "./Beranda.vue?vue&type=style&index=0&id=c249aff8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c249aff8",
  null
  
)

export default component.exports